@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;600&display=swap");

:root {
  --body-bg: #e2e4e9;
  --app-bg: #ffffff;
  --date-bg: #6d5fff;
  --header-bg: #5c4dcf;
  --socials-color: #988eff;
  --border-color: #f0eef6;
  --text-color-light: #c4c5c8;
  --text-color-white: #fff;
  --raleway: "Raleway", sans-serif;
  --comfortaa: "Comfortaa", sans-serif;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--raleway);
  transition: all 0.3s ease;
  user-select: none;
  -webkit-user-select: none;
}
button:focus {
  outline: none;
}
body {
  background-color: var(--body-bg);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

#app {
  display: grid;
  grid-template-columns: repeat(3, 10% 50% 40%);
  height: 800px;
  width: 1200px;
  background-color: #fff;
}
#calendar-parent,
#events,
#eventBody {
  border-left: 1px solid var(--border-color) !important;
}
#social {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}
#social img {
  width: 1.8rem;
  margin-bottom: 1.5rem;
}
#calendar-parent {
  display: grid;
  grid-template-rows: 15% 85%;
  padding: 0 1.5rem;
}

#hello {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.greetings {
  font-size: 1.6rem;
  font-weight: 500;
}
.name {
  font-weight: 600;
}

/* Calendar Styles */
#calendar {
  padding: 1rem 0;
  overflow: auto;
}
.calendar__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.previous,
.next,
.reset {
  padding: 8px 12px;
  color: var(--date-bg);
  border: none;
  cursor: pointer;
  background: var(--body-bg);
  margin: 2px;
  border-radius: 50%;
  font-weight: bold;
  font-family: var(--comfortaa);
  user-select: none;
}
.previous:hover,
.next:hover {
  background: var(--text-color-light);
}
.reset {
  color: var(--body-bg);
  position: relative;
}
.reset:hover {
  background: var(--text-color-light);
  color: var(--text-color-light);
}
.reset::after {
  content: "";
  background: url("../../static/images/undo.svg");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 99px;
}
.calendar__body * {
  font-family: var(--comfortaa);
  font-weight: 400;
}
.weekdays {
  color: var(--text-color-light);
  font-weight: 600;
}
.weekdays > div {
  padding: 1rem 0;
  font-size: 0.8rem;
}
.calendar__body .weekdays,
.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  gap: 0 5px;
}
.days > div {
  height: 50px;
  padding: 5px 0px;
  font-weight: 600;
  display: grid;
  place-content: center;
}
.days > div:nth-child(1),
.days > div:nth-child(8),
.days > div:nth-child(15),
.days > div:nth-child(22),
.days > div:nth-child(29),
.days > div:nth-child(36),
.days > div:nth-child(7),
.days > div:nth-child(14),
.days > div:nth-child(21),
.days > div:nth-child(28),
.days > div:nth-child(35),
.days > div:nth-child(42),
.weekdays > div:first-child,
.weekdays > div:last-child {
  color: var(--text-color-light);
  background: #c2c2c449;
}

.date {
  color: rgba(0, 0, 0, 0.822) !important;
}
.date:hover {
  background-color: var(--body-bg);
  cursor: pointer;
}
[data-selected] {
  background: var(--body-bg) !important;
}

.date.hasTasks {
  position: relative;
}
.date.hasTasks::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background: var(--date-bg);
  border-radius: 50%;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}
.today {
  background: var(--date-bg) !important;
  border-radius: 2px;
  color: var(--text-color-white) !important;
}
.today.hasTasks::after {
  background: var(--text-color-white);
}
#events {
  display: grid;
  grid-template-rows: 15% 85%;
  overflow-x: hidden;
  height: 100%;
}
.events--addEventStateActive {
  grid-template-rows: 0% 0% 100% !important;
}
.header {
  background: var(--header-bg);
  display: flex;
}
.current-date {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.current-date h2 {
  font-size: 1rem;
  color: var(--text-color-white);
}
button.add-new-event {
  background: var(--date-bg);
  width: 40%;
  color: var(--text-color-white);
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  user-select: none;
}
button.add-new-event:hover {
  background: #746ac2;
}

.timeline {
  overflow: auto;
}

.event {
  min-height: 120px;
  padding: 0px 10px 15px 10px;
  display: grid;
  grid-template-rows: 20% auto;
}

.event:first-child {
  padding-top: 15px;
}
.event-header {
  color: var(--text-color-light);
  font-family: var(--comfortaa);
  display: flex;
  align-items: center;
  user-select: none;
  font-size: 0.8rem;
}
.event-header::after {
  content: "";
  display: inline-block;
  height: 2px;
  width: 80%;
  margin-left: 15px;
  background: var(--border-color);
}
.event__body {
  cursor: pointer;
  height: 130px;
  overflow-y: scroll;
  mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 17px, black 17px);
  mask-size: 100% 20000px;
  mask-position: left bottom;
  -webkit-mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 17px, black 17px);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;
}
.event__body:hover {
  -webkit-mask-position: left top;
}
.event__body span {
  display: inline-block;
  padding: 15px 10px;
  font-weight: 600;
  border-radius: 5px;
  margin: 5px;
}
.event__body.taskMode {
  position: relative;
  cursor: pointer;
}

.event__body.taskMode::after {
  content: "✔";
  position: absolute;
  height: 35px;
  width: 35px;
  display: grid;
  place-content: center;
  color: var(--text-color-white);
  font-weight: 600;
  border-radius: 99px;
  cursor: pointer;
  font-size: 1.5 rem;
  background-color: rgb(18, 173, 18);
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
}
/* User name popup */
#name,
.add-task,
.task {
  position: absolute;
  background: rgba(0, 0, 0, 0.938);
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
}
.input-wrapper,
#eventBody {
  position: relative;
  width: 400px;
  background: white;
  display: grid;
  place-content: center;
  gap: 20px;
  border: 2px solid var(--header-bg);
  border-radius: 5px;
  padding: 1rem;
}
#events .input-wrapper,
#eventBody {
  width: 100%;
  border: none;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 100px 3rem 0 3rem;
}
#user {
  padding: 15px;
  font-size: 1.3rem;
}
/* util classes */
.not-date {
  color: rgba(151, 151, 151, 0.432);
  font-weight: 400;
  pointer-events: none;
}
.btn {
  padding: 10px 0;
  background: var(--date-bg);
  border: none;
  color: white;
  cursor: pointer;
}

/* add task popup */
[name="taskHeading"],
[name="taskURL"] {
  padding: 10px 5px;
}

/* notification */
.notify {
  padding: 15px;
  background: var(--date-bg);
  color: var(--text-color-white);
  position: absolute;
  bottom: 10%;
  right: 5%;
  border-radius: 5px;
}
.notify span {
  background: var(--text-color-white);
  color: black;
}
textarea {
  padding: 10px;
  resize: none;
}
.introjs-tooltip {
  color: white;
  background-color: var(--date-bg) !important;
}
.introjs-tooltip * {
  font-family: var(--comfortaa) !important;
}
.introjs-tooltip-title {
  font-weight: 600;
  font-size: 1rem !important;
}
.introjs-tooltiptext {
  font-family: var(--raleway) !important;
}
.introjs-skipbutton {
  display: none;
}
.introjs-button {
  background: white !important;
  color: black !important;
  border: none;
}
.introjs-progress {
  background: var(--text-color-white) !important;
}
.introjs-progressbar {
  background: var(--text-color-light) !important;
}
#eventBody * {
  user-select: text;
  -webkit-user-select: text;
}
.eventOuterLink {
  text-decoration: none;
  color: var(--date-bg);
}
.eventOuterLink:hover {
  text-decoration: underline;
}
.eventOuterLink:active {
  color: var(--date-bg);
}
.eventOuterLink::before {
  content: "";
  background: transparent url("../../static/images/link.svg") 0 0 no-repeat;
  background-size: 16px;
  content: "";
  display: inline-block;
  height: 16px;
  margin-right: 5px;
  width: 16px;
}
.closeIcon {
  transform: rotate(-180deg);
  font-size: 40px;
  display: block;
  position: absolute;
  border-radius: 999px;
  background: var(--text-color-white);
  /* background: var(--date-bg); */
  color: var(--date-bg);
  /* color: white; */
  display: grid;
  place-content: center;
  user-select: none;
  cursor: pointer;
  top: 20px;
  left: 2.8rem;
}
small {
  font-weight: bold;
}
.disabled {
  pointer-events: none;
}
/* scrollbar */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background: #c4c5c8;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
  /* box-shadow: inset 7px 10px 12px #f0f0f0; */
}

/* media queries */
@media screen and (max-width: 720px) {
  body {
    overflow-x: hidden;
    height: auto;
  }
  #app {
    margin: 20px 0;
    height: 100%;
    width: 95vw;
    grid-template-columns: 1fr;
  }
  #social {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
  }
  #social img {
    margin: 5px 10px;
  }
  #calendar-parent,
  #events {
    grid-template-rows: auto auto;
  }
  #calendar-parent {
    padding: 5px;
  }
  #hello {
    justify-content: center;
  }
  .header {
    height: 60px;
  }
  .timeline {
    height: 400px;
  }
  .input-wrapper {
    width: 100%;
  }
  .current-date h2 {
    text-align: center;
  }
}
/* animations */

@-webkit-keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
